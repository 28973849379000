<template>
	<div class="total drag" v-draw id="RqSoftNumber">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="zhuangshizb"></div>
				<div class="zhuangshizj">{{projectData.project}}_软测量数据统计</div>
				<div class="zhuangshiyb" @click="closeCompon">
					<div class="icon"></div>
				</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="content">
			<!-- 第一块儿 -->
			<div class="one">
				<div class="flex">
					<div class="first_word">触发条件设定</div>
					<div class="second_word">单位</div>
					<div class="third_word">注：下位程序中可手动触发</div>
				</div>
			</div>
			<!-- 第二块儿 -->
			<div class="two flex">
				<div>
					<div class="first_line">
						<div class="flex">
							<div class="column1">高炉煤气流量</div>
							<div class="column2" @click="toCompon(2, 'GLMQLL_AVG', 'MCSQL_TJ', 'GLMQLL_AVG_NG3','高炉煤气流量统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.GLMQLL_AVG,
                    'GLMQLL_AVG',
                    'MCSQL_TJ',
                    'GLMQLL_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.GLMQLL_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">转炉煤气流量</div>
							<div class="column2" @click="toCompon(2, 'ZLMQLL_AVG', 'MCSQL_TJ', 'ZLMQLL_AVG_NG3','转炉煤气流量均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.ZLMQLL_AVG,
                    'ZLMQLL_AVG',
                    'MCSQL_TJ',
                    'ZLMQLL_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.ZLMQLL_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">焦炉煤气流量</div>
							<div class="column2" @click="toCompon(2, 'JLMQLL_AVG', 'MCSQL_TJ', 'JLMQLL_AVG_NG3','转炉煤气流量均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.JLMQLL_AVG,
                    'JLMQLL_AVG',
                    'MCSQL_TJ',
                    'JLMQLL_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.JLMQLL_AVG }}
							</div>
						</div>
					</div>
					<div class="first_line">
						<div class="flex">
							<div class="column1">高炉煤气压力</div>
							<div class="column2" @click="toCompon(2, 'GLMQYL_AVG', 'MCSQL_TJ', 'GLMQYL_AVG_NG3','高炉煤气压力统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.GLMQYL_AVG,
                    'GLMQYL_AVG',
                    'MCSQL_TJ',
                    'GLMQYL_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.GLMQYL_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">转炉煤气压力</div>
							<div class="column2" @click="toCompon(2, 'ZLMQYL_AVG', 'MCSQL_TJ', 'ZLMQYL_AVG_NG3','转炉煤气压力均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.ZLMQYL_AVG,
                    'ZLMQYL_AVG',
                    'MCSQL_TJ',
                    'ZLMQYL_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.ZLMQYL_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">焦炉煤气压力</div>
							<div class="column2" @click="toCompon(2, 'JLMQYL_AVG', 'MCSQL_TJ', 'JLMQYL_AVG_NG3','转炉煤气压力均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.JLMQYL_AVG,
                    'JLMQYL_AVG',
                    'MCSQL_TJ',
                    'JLMQYL_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.JLMQYL_AVG }}
							</div>
						</div>
					</div>
					<div class="first_line">
						<div class="flex">
							<div class="column1">高炉煤气阀位</div>
							<div class="column2" @click="toCompon(2, 'GLMQFW_AVG', 'MCSQL_TJ', 'GLMQFW_AVG_NG3','高炉煤气阀位统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.GLMQFW_AVG,
                    'GLMQFW_AVG',
                    'MCSQL_TJ',
                    'GLMQFW_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.GLMQFW_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">转炉煤气阀位</div>
							<div class="column2" @click="toCompon(2, 'ZLMQFW_AVG', 'MCSQL_TJ', 'ZLMQFW_AVG_NG3','转炉煤气阀位均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.ZLMQFW_AVG,
                    'ZLMQFW_AVG',
                    'MCSQL_TJ',
                    'ZLMQFW_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.ZLMQFW_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">焦炉煤气阀位</div>
							<div class="column2" @click="toCompon(2, 'JLMQFW_AVG', 'MCSQL_TJ', 'JLMQFW_AVG_NG3','转炉煤气阀位均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.JLMQFW_AVG,
                    'JLMQFW_AVG',
                    'MCSQL_TJ',
                    'JLMQFW_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.JLMQFW_AVG }}
							</div>
						</div>
					</div>
				</div>
				<div class="bg">
					<div class="first_line1">
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.MQLL_E,
                    '高炉煤气流量',
                    'MQLL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.MQLL_E }}
							</div>
							<div class="column4">万m3/h</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.ZLMQLL_E,
                    '转炉煤气流量',
                    'ZLMQLL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.ZLMQLL_E }}
							</div>
							<div class="column4">万m3/h</div>
							<div class="column5">
								{{ infoList.MCSQL_TJ.MQLL_OK ? "条件满足" : "条件不满足" }}
							</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.JLMQLL_E,
                    '焦炉煤气流量',
                    'JLMQLL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.JLMQLL_E }}
							</div>
							<div class="column4">万m3/h</div>
						</div>
					</div>
					<div class="first_line">
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.MQYL_E,
                    '高炉煤气压力',
                    'MQYL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.MQYL_E }}
							</div>
							<div class="column4">KPa</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.ZLMQYL_E,
                    '转炉煤气压力',
                    'ZLMQYL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.ZLMQYL_E }}
							</div>
							<div class="column4">KPa</div>
							<div class="column5">
								{{ infoList.MCSQL_TJ.MQYL_OK ? "条件满足" : "条件不满足" }}
							</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.JLMQYL_E,
                    '焦炉煤气压力',
                    'JLMQYL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.JLMQYL_E }}
							</div>
							<div class="column4">KPa</div>
							<div class="column6">
								{{ infoList.MCALARM.GLYX ? "锅炉运行" : "锅炉备用" }}
							</div>
						</div>
					</div>
					<div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.MQFW_E,
                    '高炉煤气阀位',
                    'MQFW_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.MQFW_E }}
							</div>
							<div class="column4">%阀位</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.ZLMQFW_E,
                    '转炉煤气阀位',
                    'ZLMQFW_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.ZLMQFW_E }}
							</div>
							<div class="column4">%阀位</div>
							<div class="column5">
								{{ infoList.MCSQL_TJ.MQFW_OK ? "条件满足" : "条件不满足" }}
							</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.JLMQFW_E,
                    '焦炉煤气阀位',
                    'JLMQFW_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.JLMQFW_E }}
							</div>
							<div class="column4">%阀位</div>
						</div>
					</div>
				</div>
				<div>
					<div class="column8">
						{{ infoList.MCSQL_TJ.RCL_MQ_S ? "条件满足" : "条件不满足" }}
					</div>
				</div>
				<div class="last_one">
					<div class="column9">煤<br />气<br />软<br />测<br />量</div>
				</div>
			</div>
			<div class="middle flex">
				<div class="column1">吨煤气耗</div>
				<div class="column2" @click="toCompon(2, 'JLMQFW_AVG', 'MCSQL_TJ', 'JLMQFW_AVG_NG3','转炉煤气阀位均值')" @dblclick="
            Cclick(
              infoList.MCSQL_TJ.JLMQFW_AVG,
              'JLMQFW_AVG',
              'MCSQL_TJ',
              'JLMQFW_AVG_NG3'
            )
          ">
					{{ infoList.MCSQL_TJ.JLMQFW_AVG }}
				</div>
				<div class="column3">m3/h</div>
			</div>
			<!-- 第三块儿 -->
			<div class="three flex">
				<div>
					<div class="second_line">
						<div class="flex">
							<div class="column1">送风流量1</div>
							<div class="column2" @click="toCompon(2, 'SFLL1_AVG', 'MCSQL_TJ', 'SFLL1_AVG_NG3','送风１流量统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFLL1_AVG,
                    'SFLL1_AVG',
                    'MCSQL_TJ',
                    'SFLL1_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFLL1_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">送风流量2</div>
							<div class="column2" @click="toCompon(2, 'SFLL2_AVG', 'MCSQL_TJ', 'SFLL2_AVG_NG3','送风２流量统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFLL2_AVG,
                    'SFLL2_AVG',
                    'MCSQL_TJ',
                    'SFLL2_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFLL2_AVG }}
							</div>
						</div>
					</div>
					<div class="second_line">
						<div class="flex">
							<div class="column1">送风压力1</div>
							<div class="column2" @click="toCompon(2, 'SFYL1_AVG', 'MCSQL_TJ', 'SFYL1_AVG_NG3','送风１压力统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFYL1_AVG,
                    'SFYL1_AVG',
                    'MCSQL_TJ',
                    'SFYL1_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFYL1_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">送风压力2</div>
							<div class="column2" @click="toCompon(2, 'SFYL2_AVG', 'MCSQL_TJ', 'SFYL2_AVG_NG3','送风２压力统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFYL2_AVG,
                    'SFYL2_AVG',
                    'MCSQL_TJ',
                    'SFYL2_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFYL2_AVG }}
							</div>
						</div>
					</div>
					<div class="second_line">
						<div class="flex">
							<div class="column1">送风阀位1</div>
							<div class="column2" @click="toCompon(2, 'SFFW1_AVG', 'MCSQL_TJ', 'SFFW1_AVG_NG3','送风１阀位统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFFW1_AVG,
                    'SFFW1_AVG',
                    'MCSQL_TJ',
                    'SFFW1_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFFW1_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">送风阀位2</div>
							<div class="column2" @click="toCompon(2, 'SFFW2_AVG', 'MCSQL_TJ', 'SFFW2_AVG_NG3','送风２阀位统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFFW2_AVG,
                    'SFFW2_AVG',
                    'MCSQL_TJ',
                    'SFFW2_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFFW2_AVG }}
							</div>
						</div>
					</div>
					<div class="second_line">
						<div class="flex">
							<div class="column1">送风电流1</div>
							<div class="column2" @click="toCompon(2, 'SFDL1_AVG', 'MCSQL_TJ', 'SFDL1_AVG_NG','送风１电流统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFDL1_AVG,
                    'SFDL1_AVG',
                    'MCSQL_TJ',
                    'SFDL1_AVG_NG'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFDL1_AVG }}
							</div>
						</div>
						<div class="flex">
							<div class="column1">送风电流2</div>
							<div class="column2" @click="toCompon(2, 'SFDL2_AVG', 'MCSQL_TJ', 'SFDL2_AVG_NG3','送风2电流统计均值')"
								@dblclick="
                  Cclick(
                    infoList.MCSQL_TJ.SFDL2_AVG,
                    'SFDL2_AVG',
                    'MCSQL_TJ',
                    'SFDL2_AVG_NG3'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFDL2_AVG }}
							</div>
						</div>
					</div>
				</div>
				<div class="bg">
					<div class="second_line1">
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFLL_E,
                    '送风流量1',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFLL_E }}
							</div>
							<div class="column4">万m3/h</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFLL_E,
                    '送风流量2',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFLL_E }}
							</div>
							<div class="column4">万m3/h</div>
							<div class="column5">
								{{ infoList.MCSQL_TJ.SFLL_OK ? "条件满足" : "条件不满足" }}
							</div>
						</div>
					</div>
					<div class="second_line2">
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFLL_E,
                    '送风压力1',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFLL_E }}
							</div>
							<div class="column4">KPa</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFLL_E,
                    '送风压力2',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFLL_E }}
							</div>
							<div class="column4">KPa</div>
							<div class="column5">
								{{ infoList.MCSQL_TJ.SFYL_OK ? "条件满足" : "条件不满足" }}
							</div>
						</div>
					</div>
					<div class="second_line2">
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFFW_E,
                    '送风阀位1',
                    'SFFW_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFFW_E }}
							</div>
							<div class="column4">%阀位</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFFW_E,
                    '送风阀位2',
                    'SFFW_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFFW_E }}
							</div>
							<div class="column4">%阀位</div>
							<div class="column5">
								{{ infoList.MCSQL_TJ.SFFW_OK ? "条件满足" : "条件不满足" }}
							</div>
						</div>
					</div>
					<div class="second_line2">
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFDL_E,
                    '送风电流1',
                    'SFDL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFDL_E }}
							</div>
							<div class="column4">A</div>
							<div class="column7">
								{{ infoList.MCSQL_TJ.SFDL_OK ? "条件满足" : "条件不满足" }}
							</div>
						</div>
						<div class="flex">
							<div class="shadow column3" @click="
                  toIpt(
                    infoList.MCSQL_TJ.SFDL_E,
                    '送风电流2',
                    'SFDL_E',
                    'MCSQL_TJ'
                  )
                ">
								{{ infoList.MCSQL_TJ.SFDL_E }}
							</div>
							<div class="column4">A</div>
							<div class="column6">
								{{ infoList.MCALARM.GLYX ? "锅炉运行" : "锅炉备用" }}
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="column8">
						{{ infoList.MCSQL_TJ.RCL_FL_S ? "条件满足" : "条件不满足" }}
					</div>
				</div>
				<div class="last_one">
					<div class="column9">风<br />量<br />软<br />测<br />量</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
		<Historical v-if="isHshow" @sendStatus="isHshow = false" :historyname="historyname" :node="node"
			:Lkname="Lkname" :chName="chName" :infoList="infoList"></Historical>
		<Firstcontrol :infoList="infoList" :isIndex="isIndex" :historyname="Firstcontrolname"
			:titname="Firstcontroltitname" :node="Firstcontrolnode" v-if="isFshow" @sendStatus="isFshow = false">
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus="isRshow = false" :titname="Rsftitname" :infoList="infoList"
			:historyname="Rsfname" :node="Rsfnode"></Rsf>
		<ParameterYh v-if="isComShow" :infoList="infoList" @sendStatus="isshowfase"></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList">
		</Manual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue"; //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				Firstcontrolname: "",
				Firstcontroltitname: "",
				Firstcontrolnode: "",
				isFshow: false,
				Rsfname: "",
				Rsfnode: "",
				Rsftitname: "",
				projectData: "",
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			};
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
		},
		methods: {
			DataJson(data) {
				let strNode = data[0];
				let strMark = data[1];
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2];
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					let pathname = "";
					switch (key) {
						case 1:
							pathname = "firstcontol";
							break;
						case 2:
							pathname = "rqkfirstcontol";
							break;
						case 3:
							pathname = "RqRfirstcontol";
							break;
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname,
						},
					});
					window.open(newurl.href, "_blank");
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}
					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			isshowfase() {
				this.isComShow = false;
			},
			closeCompon() {
				this.$emit("sendStatus", "RqSoftNumber", false);
			},
			isClose(val, val1) {
				switch (val) {
					case "Historical":
						return (this.isHshow = val1);
				}
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type, status) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type, status);
				}
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1);
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (this.isHshow = true, this.chName = name4);
						case 4:
							return (
								(this.isRshow = true),
								(this.Rsfname = name),
								(this.Rsfnode = name2),
								(this.Rsftitname = titname)
							);
					}
				}, 300);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.total {
		width: 53vw;
		height: 70vh;
		background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
		background-size: 100% 100%;
		// margin-left: 24vw;
		margin-left: 14vw;
		margin-top: 10vh;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
		}

		.zhuangshizb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-top: 2vh;
		}

		.zhuangshizj {
			width: 19vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1.3vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 1vw;
		}

		.zhuangshiyb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 1vw;
			margin-top: 2vh;
		}

		.icon {
			width: 2vw;
			height: 3.5vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 13vw;
			margin-top: -0.5vh;
			cursor: pointer;
		}

		.content {
			width: 50vw;
			height: 60vh;
			border: 1px solid rgba(0, 228, 255, 0.2);
			box-sizing: border-box;
			margin-top: 2.5vh;
			margin-left: 1.5vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;

			.one {
				height: 3vh;
				background-color: #013349;
				font-size: 1.2vw;
				line-height: 3vh;
				color: #9addff;

				.first_word {
					margin-left: 11vw;
				}

				.second_word {
					margin-left: 1vw;
				}

				.third_word {
					font-size: 0.6vw;
					line-height: 3vh;
					margin-left: 10vw;
				}
			}

			.middle {
				margin-left: 2.2vw;
				font-size: 0.8vw;
				line-height: 2.8vh;
				margin-top: -1.5vh;

				.column1 {
					width: 4vw;
					color: #8aeaff;
					margin-left: 0.7vw;
				}

				.column2 {
					width: 2vw;
					color: #2fc3e3;
					margin-left: 0.6vw;
					cursor: pointer;
				}

				.column3 {
					width: 3vw;
					color: #2fc3e3;
					text-align: center;
				}
			}

			.two {
				height: 28.5vh;
				border-top: 1px solid rgba(0, 228, 255, 0.2);
				border-bottom: 1px solid rgba(0, 228, 255, 0.2);
				box-sizing: border-box;
				font-size: 0.8vw;
				line-height: 2.7vh;
				text-align: right;

				.column1 {
					width: 6vw;
					color: #8aeaff;
					margin-left: 0.7vw;
				}

				.column2 {
					width: 2vw;
					color: #2fc3e3;
					margin-left: 0.6vw;
					cursor: pointer;
				}

				.first_line {
					margin-top: 1vh;
				}

				.shadow {
					width: 3.6vw;
					height: 2.5vh;
					background-color: #001b2860;
					box-shadow: 0vw 0vw 0vw 0vw #15384c;
					border-radius: 0vw;
					opacity: 0.4;
					text-align: center;
					color: #00ffb4;
					cursor: pointer;
				}

				.bg {
					width: 30vw;
					height: 26vh;
					margin-top: 1vh;
					margin-left: 0.8vw;
					background-image: url("~@/assets/images/RqBoiler/soft1.png");
					background-size: 100% 100%;

					.first_line1 {
						margin-top: 0.5vh;
					}

					.first_line {
						margin-top: 1vh;
					}

					.column3 {
						margin-left: 2.5vw;
					}

					.column4 {
						width: 5vw;
						color: #2fc3e3;
						text-align: center;
					}

					.column5 {
						width: 5vw;
						font-size: 0.8vw;
						line-height: 2vh;
						margin-left: 10.21vw;
						margin-top: -0.6vh;
						color: #136afa;
						text-align: center;
					}

					.column6 {
						font-size: 0.8vw;
						line-height: 2vh;
						margin-left: 11.5vw;
						margin-top: 1.5vh;
						color: #ec15ff;
					}
				}

				.column8 {
					margin-left: 0.2vw;
					margin-top: 12.6vh;
					color: #136afa;
				}

				.last_one {
					width: 1.9vw;
					height: 16.9vh;
					background-color: #001b2860;
					box-shadow: 0vw 0vw 0vw 0vw #15384c;
					border-radius: 0vw;
					opacity: 0.4;
					text-align: center;
					color: #00e4ff;
					margin-top: 5.75vh;
					margin-left: 1vw;

					.column9 {
						margin-top: 1.25vh;
					}
				}
			}

			.three {
				height: 28.5vh;
				font-size: 0.8vw;
				line-height: 2.8vh;
				text-align: right;
				margin-top: -1vh;

				.column1 {
					width: 6vw;
					color: #8aeaff;
					margin-left: 0.7vw;
				}

				.column2 {
					width: 2vw;
					color: #2fc3e3;
					margin-left: 0.6vw;
					cursor: pointer;
				}

				.second_line {
					margin-top: 1vh;
				}

				.shadow {
					width: 3.6vw;
					height: 2.5vh;
					background-color: #001b2860;
					box-shadow: 0vw 0vw 0vw 0vw #15384c;
					border-radius: 0vw;
					opacity: 0.4;
					text-align: center;
					color: #00ffb4;
					cursor: pointer;
				}

				.bg {
					width: 30vw;
					height: 26vh;
					margin-top: 1vh;
					margin-left: 0.8vw;
					background-image: url("~@/assets/images/RqBoiler/soft2.png");
					background-size: 100% 100%;

					.second_line1 {
						margin-top: 0.5vh;
					}

					.second_line2 {
						margin-top: 1vh;
					}

					.column3 {
						margin-left: 2.5vw;
					}

					.column4 {
						width: 5vw;
						color: #2fc3e3;
						text-align: center;
					}

					.column5 {
						width: 5vw;
						font-size: 0.8vw;
						line-height: 2vh;
						margin-left: 10vw;
						margin-top: -1.6vh;
						color: #136afa;
						text-align: center;
					}

					.column6 {
						font-size: 0.8vw;
						line-height: 2vh;
						margin-left: 11vw;
						margin-top: 1.2vh;
						color: #ec15ff;
					}

					.column7 {
						font-size: 0.8vw;
						line-height: 2vh;
						margin-left: 11.05vw;
						margin-top: 1.1vh;
						color: #136afa;
					}
				}

				.column8 {
					margin-left: 0.2vw;
					margin-top: 12.8vh;
					color: #136afa;
				}

				.last_one {
					width: 1.9vw;
					height: 16.9vh;
					background-color: #001b2860;
					box-shadow: 0vw 0vw 0vw 0vw #15384c;
					border-radius: 0vw;
					opacity: 0.4;
					text-align: center;
					color: #00e4ff;
					margin-top: 6vh;
					margin-left: 1vw;

					.column9 {
						margin-top: 1.25vh;
					}
				}
			}
		}
	}
</style>
