var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"RqSoftNumber"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"zhuangshizb"}),_c('div',{staticClass:"zhuangshizj"},[_vm._v(_vm._s(_vm.projectData.project)+"_软测量数据统计")]),_c('div',{staticClass:"zhuangshiyb",on:{"click":_vm.closeCompon}},[_c('div',{staticClass:"icon"})])])]),_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"two flex"},[_c('div',[_c('div',{staticClass:"first_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("高炉煤气流量")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'GLMQLL_AVG', 'MCSQL_TJ', 'GLMQLL_AVG_NG3','高炉煤气流量统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.GLMQLL_AVG,
                    'GLMQLL_AVG',
                    'MCSQL_TJ',
                    'GLMQLL_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.GLMQLL_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("转炉煤气流量")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'ZLMQLL_AVG', 'MCSQL_TJ', 'ZLMQLL_AVG_NG3','转炉煤气流量均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.ZLMQLL_AVG,
                    'ZLMQLL_AVG',
                    'MCSQL_TJ',
                    'ZLMQLL_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.ZLMQLL_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("焦炉煤气流量")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'JLMQLL_AVG', 'MCSQL_TJ', 'JLMQLL_AVG_NG3','转炉煤气流量均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.JLMQLL_AVG,
                    'JLMQLL_AVG',
                    'MCSQL_TJ',
                    'JLMQLL_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.JLMQLL_AVG)+" ")])])]),_c('div',{staticClass:"first_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("高炉煤气压力")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'GLMQYL_AVG', 'MCSQL_TJ', 'GLMQYL_AVG_NG3','高炉煤气压力统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.GLMQYL_AVG,
                    'GLMQYL_AVG',
                    'MCSQL_TJ',
                    'GLMQYL_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.GLMQYL_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("转炉煤气压力")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'ZLMQYL_AVG', 'MCSQL_TJ', 'ZLMQYL_AVG_NG3','转炉煤气压力均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.ZLMQYL_AVG,
                    'ZLMQYL_AVG',
                    'MCSQL_TJ',
                    'ZLMQYL_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.ZLMQYL_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("焦炉煤气压力")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'JLMQYL_AVG', 'MCSQL_TJ', 'JLMQYL_AVG_NG3','转炉煤气压力均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.JLMQYL_AVG,
                    'JLMQYL_AVG',
                    'MCSQL_TJ',
                    'JLMQYL_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.JLMQYL_AVG)+" ")])])]),_c('div',{staticClass:"first_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("高炉煤气阀位")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'GLMQFW_AVG', 'MCSQL_TJ', 'GLMQFW_AVG_NG3','高炉煤气阀位统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.GLMQFW_AVG,
                    'GLMQFW_AVG',
                    'MCSQL_TJ',
                    'GLMQFW_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.GLMQFW_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("转炉煤气阀位")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'ZLMQFW_AVG', 'MCSQL_TJ', 'ZLMQFW_AVG_NG3','转炉煤气阀位均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.ZLMQFW_AVG,
                    'ZLMQFW_AVG',
                    'MCSQL_TJ',
                    'ZLMQFW_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.ZLMQFW_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("焦炉煤气阀位")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'JLMQFW_AVG', 'MCSQL_TJ', 'JLMQFW_AVG_NG3','转炉煤气阀位均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.JLMQFW_AVG,
                    'JLMQFW_AVG',
                    'MCSQL_TJ',
                    'JLMQFW_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.JLMQFW_AVG)+" ")])])])]),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"first_line1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.MQLL_E,
                    '高炉煤气流量',
                    'MQLL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.MQLL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("万m3/h")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.ZLMQLL_E,
                    '转炉煤气流量',
                    'ZLMQLL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.ZLMQLL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("万m3/h")]),_c('div',{staticClass:"column5"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.MQLL_OK ? "条件满足" : "条件不满足")+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.JLMQLL_E,
                    '焦炉煤气流量',
                    'JLMQLL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.JLMQLL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("万m3/h")])])]),_c('div',{staticClass:"first_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.MQYL_E,
                    '高炉煤气压力',
                    'MQYL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.MQYL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("KPa")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.ZLMQYL_E,
                    '转炉煤气压力',
                    'ZLMQYL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.ZLMQYL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("KPa")]),_c('div',{staticClass:"column5"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.MQYL_OK ? "条件满足" : "条件不满足")+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.JLMQYL_E,
                    '焦炉煤气压力',
                    'JLMQYL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.JLMQYL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("KPa")]),_c('div',{staticClass:"column6"},[_vm._v(" "+_vm._s(_vm.infoList.MCALARM.GLYX ? "锅炉运行" : "锅炉备用")+" ")])])]),_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.MQFW_E,
                    '高炉煤气阀位',
                    'MQFW_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.MQFW_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("%阀位")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.ZLMQFW_E,
                    '转炉煤气阀位',
                    'ZLMQFW_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.ZLMQFW_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("%阀位")]),_c('div',{staticClass:"column5"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.MQFW_OK ? "条件满足" : "条件不满足")+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.JLMQFW_E,
                    '焦炉煤气阀位',
                    'JLMQFW_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.JLMQFW_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("%阀位")])])])]),_c('div',[_c('div',{staticClass:"column8"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.RCL_MQ_S ? "条件满足" : "条件不满足")+" ")])]),_vm._m(1)]),_c('div',{staticClass:"middle flex"},[_c('div',{staticClass:"column1"},[_vm._v("吨煤气耗")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'JLMQFW_AVG', 'MCSQL_TJ', 'JLMQFW_AVG_NG3','转炉煤气阀位均值')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.MCSQL_TJ.JLMQFW_AVG,
              'JLMQFW_AVG',
              'MCSQL_TJ',
              'JLMQFW_AVG_NG3'
            )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.JLMQFW_AVG)+" ")]),_c('div',{staticClass:"column3"},[_vm._v("m3/h")])]),_c('div',{staticClass:"three flex"},[_c('div',[_c('div',{staticClass:"second_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风流量1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFLL1_AVG', 'MCSQL_TJ', 'SFLL1_AVG_NG3','送风１流量统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFLL1_AVG,
                    'SFLL1_AVG',
                    'MCSQL_TJ',
                    'SFLL1_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFLL1_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风流量2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFLL2_AVG', 'MCSQL_TJ', 'SFLL2_AVG_NG3','送风２流量统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFLL2_AVG,
                    'SFLL2_AVG',
                    'MCSQL_TJ',
                    'SFLL2_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFLL2_AVG)+" ")])])]),_c('div',{staticClass:"second_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风压力1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFYL1_AVG', 'MCSQL_TJ', 'SFYL1_AVG_NG3','送风１压力统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFYL1_AVG,
                    'SFYL1_AVG',
                    'MCSQL_TJ',
                    'SFYL1_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFYL1_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风压力2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFYL2_AVG', 'MCSQL_TJ', 'SFYL2_AVG_NG3','送风２压力统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFYL2_AVG,
                    'SFYL2_AVG',
                    'MCSQL_TJ',
                    'SFYL2_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFYL2_AVG)+" ")])])]),_c('div',{staticClass:"second_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风阀位1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFFW1_AVG', 'MCSQL_TJ', 'SFFW1_AVG_NG3','送风１阀位统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFFW1_AVG,
                    'SFFW1_AVG',
                    'MCSQL_TJ',
                    'SFFW1_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFFW1_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风阀位2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFFW2_AVG', 'MCSQL_TJ', 'SFFW2_AVG_NG3','送风２阀位统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFFW2_AVG,
                    'SFFW2_AVG',
                    'MCSQL_TJ',
                    'SFFW2_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFFW2_AVG)+" ")])])]),_c('div',{staticClass:"second_line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风电流1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFDL1_AVG', 'MCSQL_TJ', 'SFDL1_AVG_NG','送风１电流统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFDL1_AVG,
                    'SFDL1_AVG',
                    'MCSQL_TJ',
                    'SFDL1_AVG_NG'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFDL1_AVG)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("送风电流2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(2, 'SFDL2_AVG', 'MCSQL_TJ', 'SFDL2_AVG_NG3','送风2电流统计均值')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSQL_TJ.SFDL2_AVG,
                    'SFDL2_AVG',
                    'MCSQL_TJ',
                    'SFDL2_AVG_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFDL2_AVG)+" ")])])])]),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"second_line1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFLL_E,
                    '送风流量1',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFLL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("万m3/h")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFLL_E,
                    '送风流量2',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFLL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("万m3/h")]),_c('div',{staticClass:"column5"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFLL_OK ? "条件满足" : "条件不满足")+" ")])])]),_c('div',{staticClass:"second_line2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFLL_E,
                    '送风压力1',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFLL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("KPa")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFLL_E,
                    '送风压力2',
                    'SFLL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFLL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("KPa")]),_c('div',{staticClass:"column5"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFYL_OK ? "条件满足" : "条件不满足")+" ")])])]),_c('div',{staticClass:"second_line2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFFW_E,
                    '送风阀位1',
                    'SFFW_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFFW_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("%阀位")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFFW_E,
                    '送风阀位2',
                    'SFFW_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFFW_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("%阀位")]),_c('div',{staticClass:"column5"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFFW_OK ? "条件满足" : "条件不满足")+" ")])])]),_c('div',{staticClass:"second_line2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFDL_E,
                    '送风电流1',
                    'SFDL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFDL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("A")]),_c('div',{staticClass:"column7"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFDL_OK ? "条件满足" : "条件不满足")+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow column3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MCSQL_TJ.SFDL_E,
                    '送风电流2',
                    'SFDL_E',
                    'MCSQL_TJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.SFDL_E)+" ")]),_c('div',{staticClass:"column4"},[_vm._v("A")]),_c('div',{staticClass:"column6"},[_vm._v(" "+_vm._s(_vm.infoList.MCALARM.GLYX ? "锅炉运行" : "锅炉备用")+" ")])])])]),_c('div',[_c('div',{staticClass:"column8"},[_vm._v(" "+_vm._s(_vm.infoList.MCSQL_TJ.RCL_FL_S ? "条件满足" : "条件不满足")+" ")])]),_vm._m(2)])]),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.isFshow)?_c('Firstcontrol',{attrs:{"infoList":_vm.infoList,"isIndex":_vm.isIndex,"historyname":_vm.Firstcontrolname,"titname":_vm.Firstcontroltitname,"node":_vm.Firstcontrolnode},on:{"sendStatus":function($event){_vm.isFshow = false}}}):_vm._e(),(_vm.isRshow)?_c('Rsf',{attrs:{"titname":_vm.Rsftitname,"infoList":_vm.infoList,"historyname":_vm.Rsfname,"node":_vm.Rsfnode},on:{"sendStatus":function($event){_vm.isRshow = false}}}):_vm._e(),(_vm.isComShow)?_c('ParameterYh',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isshowfase}}):_vm._e(),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isMshow = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"first_word"},[_vm._v("触发条件设定")]),_c('div',{staticClass:"second_word"},[_vm._v("单位")]),_c('div',{staticClass:"third_word"},[_vm._v("注：下位程序中可手动触发")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"last_one"},[_c('div',{staticClass:"column9"},[_vm._v("煤"),_c('br'),_vm._v("气"),_c('br'),_vm._v("软"),_c('br'),_vm._v("测"),_c('br'),_vm._v("量")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"last_one"},[_c('div',{staticClass:"column9"},[_vm._v("风"),_c('br'),_vm._v("量"),_c('br'),_vm._v("软"),_c('br'),_vm._v("测"),_c('br'),_vm._v("量")])])}]

export { render, staticRenderFns }